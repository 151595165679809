<template>
  <div class="en_english_a">

  <div class="class_wrap" v-for="(dir1, index1) in dirs" :key="index1" >
    <div class="class_title">
    * {{dir1.name}}
    </div>
    <div class="en_main">
        <div class="en_box" v-for="(dir, index) in dir1.groups" :key="index">
            <div class="dir" @click="goPage(dir1.name + dir.name)">
              <div class="dir_wrap">
                  <img class=" icon_review_start" v-if="dir.name.indexOf(listStart.value) !== -1" src="https://img.ok-bug.com/app/star-red.png">
                  <img class="icon icon_review" v-if="dir.name.indexOf('review') !== -1" src="https://img.ok-bug.com/app/review.png">
                  <img class="icon icon_review" v-else-if="dir.name.indexOf('week') !== -1" src="https://img.ok-bug.com/app/test.png">
                  <img class="icon"  src="https://img.ok-bug.com/app/dir.png" alt="">
                  <div>
                    <div class="file_name">{{dir.name.split('_')[0]}}</div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>




  </div>
</template>
<script>
export default {
  
  data(){
    return {
      dirs:'',
       startCCC:'',
       listStart: ''
    }
  },
  methods:{
     async getStart(){
     const r = await this.$api.readStar({data: {key:'date'}})
     const c = await this.$api.readStar({data: {key:'list'}})

    console.log(c,'c')

    this.startCCC = r
    this.listStart = c
    // console.log(r,'333')


    },
    async getData(){
       const data  = await this.$api.getEnglish({data: {dir: 'english/'}})
       const dirs = data.dir

        const names = [ 'CET4', 'PET3', '1517LIST', 'DAILY']

        const groupBynames = []


        names.forEach(name => {


            const tempObj = {
              name,
              groups: []
            }

            dirs.forEach(item=>{
              if(item.dir.indexOf(name) !== -1) {
                  const aa = item.dir.split('/')[1].replace(name, '')
                    tempObj.groups.push({name:aa,  copy: item})
              }

            //  item.name = item.dir.split('/')[1]
            //  console.log(item,'3')
           })
          
            groupBynames.push(tempObj)
           
        })


        console.log(groupBynames,'groupBynames')
      //  dirs.forEach(item=>{
      //    item.name = item.dir.split('/')[1]
      //    console.log(item,'3')
      //  })

       this.dirs = groupBynames

       console.log(dirs,'dirsdirsdirs')
     
    },
    goPage(src) {
      this.$router.push(`/file/${src}`)
    }
  },
  mounted(){
    this.getStart()
    this.getData()
  }
}
</script>
<style lang="less">

.class_wrap {
    padding: 20px;
    .class_title {
      font-size: 14px;
      font-weight: bolder;
      padding-left: 6px;
      border-bottom: 1px solid #f5f5f5 ;

      width: 100%;
      margin-bottom: 10px;

    }
}
.en_english_a {
  font-size: 12px;
  padding-bottom: 100px;

  .dir_wrap {
    position: relative;
  }

  .icon_review {
    position: absolute;
    width: 18px  !important;
    bottom: 22px;
    right: 32px;
  }

  .en_main {
     display: flex;
    flex-wrap: wrap;
  }

 .dir {
   cursor: pointer;
   text-align: center;
   width: 100px;
   margin-bottom: 20px;
 }
 .icon {
   width: 30px;
 }

 .file_name {
   white-space: nowrap;
 }
}

.icon_review_start {
  position: absolute;
  width: 10px;
  left:20px;
  z-index: 9;
}


</style> 