<template>

<div class="en_file">

  <div class="en_operation" v-if="files.length">

    <div class="progress">


      <div class="run_progress"  :style="{width: (Math.ceil(currentIndex/files.length * 100)  )+ '%' }">
        <div class="run_info">{{currentIndex + 1}}/{{ files.length}}</div>

      </div>

    </div>

   <div class="en_font en_wwww" >
     <span v-if="files[currentIndex]">{{ !isShowWord ? files[currentIndex].word : '*****'}}  </span> 
   </div>
  <div class="en_wwww en_center">
     <div  class="en_clock"  v-if="clearTimeIndex">
          {{4 - clearTimeIndex}}

     </div>
 <!-- <span v-if="clearTimeIndex">({{clearTimeIndex}})</span> -->
    <div v-else>
       <img class="en_icon_play"  v-if="isPlay" src="https://img.ok-bug.com/app/stop.png" @click="palyStop">
       <img class="en_icon_play"  v-else  src="https://img.ok-bug.com/app/play.png" @click="palyAll">
    </div>
  </div>

  <div  class="en_wwww en_all">

     <img class="en_icon_all" v-if="!isShowWord"  src="https://img.ok-bug.com/app/hide_e.png" alt="" @click="isShowWord=!isShowWord">
     <img class="en_icon_all" v-else  src="https://img.ok-bug.com/app/show_e.png" alt="" @click="isShowWord=!isShowWord">

    <img class="en_icon_all"  src="https://img.ok-bug.com/app/bigAcr.png" alt="">


   
  </div>
  </div>

  <div class="en_english">
      <div class="en_box box viewport-flip" v-for="(dir, index) in files" :key="index" ref="aaa">
          
           <div :class="[' list flip', rotateIndex.indexOf(index) === -1 ? outout: inin ]">


              <div class="fanyi_wrap">

                   <div class="content_height">

                      <div class="fanyi_ba">

                        <div v-for="(tt, ii) in dir.trans" :key="ii">

                            {{tt}}

                        </div>

                      </div>

                   </div>

                  <div class="word_opera">
                    <div @click.stop="fanyiChange(index)"><img src="https://img.ok-bug.com/app/trans.png" alt=""></div>
                  </div>


              </div>


                 

            </div>


            <div :class="['dir', {active: index === currentIndex}, 'list flip',  rotateIndex.indexOf(index) !== -1 ? outout: inin]" @click="goPage(dir.name, dir.type)"  >
              <div class="dir_wrap" v-if=" startCCC.value">
                     <img v-if="dir.type==='txt' &&  startCCC.value.indexOf(dir.word) !== -1" class="icon_start" src="https://img.ok-bug.com/app/star-red.png" alt="">

                     <img v-if="dir.type==='txt' && startCCC.value.indexOf(dir.word) === -1" class="icon_start_grey" src="https://img.ok-bug.com/app/star-grey.png" alt="">

                     

                     <img  @click="complete(index, dir.word)" v-if="dir.type==='txt' && currentStart.value === dir.word" class="icon_start_grey" src="https://img.ok-bug.com/app/current.png" alt="">


                  <div  class="content_height">
                    <img v-if="dir.type==='mp3'" class="icon" src="https://img.ok-bug.com/app/audio.png" alt="">  
                    <img v-if="dir.type==='txt'" class="icon" src="https://img.ok-bug.com/app/texticon.png" alt="">

                   
        
                        <div class="english_words" >
                          <div class="english_abs">
                            {{!isShowWord ? dir.word : '*****'}}  
                          </div>

                      </div>
                  </div>
                

                <div class="word_opera">
                  <div @click.stop="fanyi(index)"  v-if="dir.type === 'mp3'"><img src="https://img.ok-bug.com/app/trans.png" alt=""></div>
                  <div @click.stop="onNextPage(dir.name)"  v-if="dir.type === 'txt'"><img src="https://img.ok-bug.com/app/lookprint.png" alt=""></div>
                </div>
              </div>
          </div>


      </div>

        <div class="en_box box viewport-flip" v-for="(item) in  lastLowCount" :key="item.name + '9999'" style="opacity:0">
        </div>

      <audio ref="audio" src />
     </div>
  </div>
</template>
<script>
export default {
  
  data(){
    return {
      files:[],
      isPlay: false,
      currentIndex: -1,
       audioDom: null,
       timeAA:null,
       clearTimeIndex:0,
       isShowWord: false,
       lowCount: 0,
       lastLowCount:[],
       rotate:'rotateY(0deg)',
       rotateIndex: [],
       startRoatte:false,
       inin: 'in',
       outout:'out',
       startCCC: {},
       currentStart: {}
    }
  },
  methods:{

    async complete(index, word) {
      

      const indexs = []
      this.files.forEach(item => {
        if(this.startCCC.value.indexOf(item.word) === -1) {
          indexs.push(item.word)
        }
      })


      console.log(indexs,'indexs')

      console.log(word,'e3')
      await this.$api.updateStar({data: {key:'lists', value: this.startCCC.value.join() + ',' +word}})

      console.log(indexs,'indexs')
      if(indexs.length >1) {
       await this.$api.updateStar({data: {key:'list', value: indexs[1]}})

      }else {
        await this.$api.updateStar({data: {key:'list', value: ''}})
      }


    this.getStart()
      console.log('完成')
    },

     async getStart(){
     const r = await this.$api.readStar({data: {key:'lists'}})


      const c = await this.$api.readStar({data: {key:'list'}})

    r.value = r.value.split(',') || []
    this.startCCC = r

    this.currentStart = c
    console.log(r,'333')


    },

    onNextPage(name){
      console.log(name,'333')
       this.$router.push(`/text?a=${name}`)
    },

    async fanyiChange(a){


      //    if(!this.files[a].trans) {
      //     const res = await this.$api.getTrans({data: {word: this.files[a].word}})
      //     this.files[a].trans = res.translation

      // }

      const findIndex = this.rotateIndex.findIndex(v=> v === a)
      // console.log(findIndex,'findIndex')
       this.rotateIndex.splice(findIndex, 1)

      


      // console.log(this.rotateIndex,'9')

      
    },
    async fanyi(a){

    

       if(!this.files[a].trans) {
          const res = await this.$api.getTrans({data: {word: this.files[a].word}})

           const {
              translation
            } = res


            // const text = 'AppleTalk Address Resolution Protocol,AppleTalk地址解析协议';
            const aaaa = translation.replace('pl.', 'pl').replace(/([a-zA-Z]+.)+/g, (e) => {
              if (e.indexOf('.') !== -1) {
                return 'xx' + e;
              }
              return e
              });

            const bb = aaaa.split('xx');
            bb.shift();
           this.files[a].trans = bb.length === 0 ? [translation] : bb


      }

        this.rotateIndex.push(a)

      

     

    },
    async getData(dir){
       const data  = await this.$api.getEnglish({data: {dir}})
       const files = data.file.filter(v => v.size > 0)
       files.forEach(item=>{
         const name = item.name.split('/')[2].split('.')[0]
         item.word = name
         item.path = `https://img.ok-bug.com/${item.name}`
         item.type = item.name.split('.')[1]
       })


       const aa  = files.length - (parseInt(files.length/this.lowCount) * this.lowCount)

      const bb = this.lowCount - aa

      for (var i=0;i<bb;i++)
    { 
       this.lastLowCount.push({name: i})
    }


    console.log(files,'0')
       this.files = files
    },

    palyStop(){
      this.isPlay = false
      this.audioDom.pause()
      clearTimeout(this.setTime)

    },

    palyAll(){
      this.isPlay = true
      this.audioDom = document.createElement('audio');

      this.currentIndex++
      if(this.currentIndex >= this.files.length) {
        this.currentIndex = 0
      }


       // 检测
      var doms = this.$refs.aaa
      var dom = doms[[this.currentIndex]]
      dom.scrollIntoView(false);
  

      this.audioDom.src = this.files[this.currentIndex].type === 'mp3' ? this.files[this.currentIndex].path : 'https://img.ok-bug.com/app/passvideo.mp3';
      console.log(this.audioDom.src,'99')
       this.audioDom.play();
        this.isPlay = true;
        this.audioDom.addEventListener(
          'ended',
          () => {


                  
                    this.setTime = setInterval(()=>{

                        if(this.clearTimeIndex >= 3) {
                          this.clearTimeIndex = 0
                          clearInterval(this.setTime)


                          this.currentIndex++
                          if(this.currentIndex >= this.files.length) {
                            this.currentIndex = 0
                          }



                            // 检测
                              var doms = this.$refs.aaa
                              var dom = doms[[this.currentIndex]]
                              dom.scrollIntoView();
                            //  console.log(dom[this.currentIndex].scrllTop)



                          this.audioDom.src = this.files[this.currentIndex].type === 'mp3' ? this.files[this.currentIndex].path : 'https://img.ok-bug.com/app/passvideo.mp3';

                          this.audioDom.play();

                         
                        return

                        }

                      this.clearTimeIndex ++


                    }, 1000)




                  

          
          },
          false
        );


    },

    goPage(src, type) {

      if(type !== 'mp3') return false
      
      if(this.isPlay) return false

      const audio = this.$refs.audio;
      audio.src = `https://img.ok-bug.com/${src}`;
      audio.play();
      audio.addEventListener(
        'ended',
        () => {
        },
        false
      );
      // this.$router.push(`/dir?file=${src}`)
    }
  },
  mounted(){
    this.getStart()
    this.lowCount = parseInt((document.body.clientWidth - 40)/ 150)
    const q = encodeURI(`english/${this.$route.params.file}/`)
    this.getData(q)



  },
  destroyed(){
      this.isPlay = false
      this.audioDom && this.audioDom.pause()
      clearTimeout(this.setTime)
  }
}
</script>
<style lang="less">

.icon_start {
  width: 10px;
  position: absolute;
  left: 20px
}

.icon_start_grey {
   width: 20px;
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.in {
	-webkit-animation-timing-function: ease-out;
	-webkit-animation-duration: 350ms;
	animation-timing-function: ease-out;
	animation-duration: 350ms;
}
.out {
	-webkit-animation-timing-function: ease-in;
	-webkit-animation-duration: 225ms;
	animation-timing-function: ease-in;
	animation-duration: 225ms;
}
.viewport-flip {
	perspective: 1000px;
	position: absolute;
}
.flip {
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateX(0); /* Needed to work around an iOS 3.1 bug that causes listview thumbs to disappear when -webkit-visibility:hidden is used. */
	backface-visibility: hidden;
	transform: translateX(0);
}
.flip.out {
	-webkit-transform: rotateY(-90deg) scale(.9);
	-webkit-animation-name: flipouttoleft;
	-webkit-animation-duration: 175ms;
	transform: rotateY(-90deg) scale(.9);
	animation-name: flipouttoleft;
	animation-duration: 175ms;
}
.flip.in {
	-webkit-animation-name: flipintoright;
	-webkit-animation-duration: 225ms;
	animation-name: flipintoright;
	animation-duration: 225ms;
}
@-webkit-keyframes flipouttoleft {
    from { -webkit-transform: rotateY(0); }
    to { -webkit-transform: rotateY(-90deg) scale(.9); }
}
@keyframes flipouttoleft {
    from { transform: rotateY(0); }
    to { transform: rotateY(-90deg) scale(.9); }
}
@-webkit-keyframes flipintoright {
    from { -webkit-transform: rotateY(90deg) scale(.9); }
    to { -webkit-transform: rotateY(0); }
}
@keyframes flipintoright {
    from { transform: rotateY(90deg) scale(.9); }
    to { transform: rotateY(0); }
}
.box {
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.list {
    position: absolute;
}ile {
  background: #f5f5f5;
  min-height: 100vh;
}

.en_english {
  font-size: 12px;
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    justify-content: space-between;
    padding-top: 0;


  .en_box {
    display: flex;
    flex-wrap: wrap;
    border-radius: 15px;
    font-weight: bolder;
    font-size: 14px;
    // white-space: nowrap;

    padding-top: 10px;

    text-align: center;
    width: 150px;


    &:after {


    }
  }
 .dir {
   cursor: pointer;
   text-align: center;
   width: 100%;
   margin-top: 15px;
   padding: 30px 0;
    background: #fff;
    border-radius: 10px;
    height: 130px;
    padding-bottom: 10px;

    position: relative;

    padding-top: 20px;
    &.active {
      // background: #fff;
      box-shadow: 0 0  12px 0 rgba(0,0,0,.1);
    }

    transition:  all 1s;

    // &:hover {
    //    transform: rotateY(180deg);

    // }

 }
 .icon {
   width: 30px;
   font-size: 0;
 }
}

.en_icon_play {
  width: 40px;
  cursor: pointer;
}

.en_icon_all {
  width: 20px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 25px;
}

.en_operation {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0px;
  padding: 0 30px;
  z-index: 11;



  .progress {
    height: 5px;
    background: red;
    width:  calc(100% - 80px);
    position: fixed;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 9;
    
    
  }


  .run_progress {
   width: 0px;
   height: 5px;
   background: -webkit-gradient(linear,left top,right top,from(transparent),color-stop(20%,#ff410f));
   position: relative;
   transition: width 1s;
   border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .run_info{
    position: absolute;
    background: #222;
    right: -8px;
    top: -10px;

    width: 80px;
    height: 23px;
    border-radius: 23px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.4);
    color: #fff;
    line-height: 23px;
    font-size: 12px;
    line-height: 23px;
    text-align: right;
    padding-right: 10px;
  }

}

.en_font {
  font-size: 20px;
  color: #222;
  font-weight: bolder;
}

.en_all {
  text-align: right;
}

.en_center {
  text-align: center;
}

.en_wwww {
  width: 200px;

}

.en_clock {
  display: inline-block;
  width:40px;
  height: 40px;
  background: red;
  border-radius: 50%;
  color: #fff;
  text-align: center;
    font-size: 26px;
    line-height:40px;
  }






.word_opera {

  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;

  border-top: 1px solid #f5f5f5;
  padding-top: 8px;


  position: absolute;
  bottom: 5px;


  // .fanyi {
    
  // }

  img {
    width: 20px;
    font-size: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}


.fanyi_wrap {
  width: 150px;
  background: #fff;
  margin-top: 15px;
  border-radius: 10px;
  height: 130px;

  
}

.fanyi_ba {

  height: 78px;
  // line-height: 50px;
   word-break: break-all !important;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  text-align: left;
  padding:10px;
  box-sizing: border-box;
  overflow: auto;

    &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #d8d8d8;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
      }

}

.content_height {
  // max-height: 78px;



}

.english_words {
  padding: 0 10px;
  // height: 50px;
  // position: relative;

  // .english_abs {
  //   position: absolute;
  //   top:50%;
  //   transform: translateY(-50%);
  //   text-align: center;
  //   width: 100%;
  //   left:0;
  //   padding: 0 10px;
  // }
  
}
</style> 