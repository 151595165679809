<template>
  <div class="text">
    <div class="tableDiv">
       <div class="rowall rowallLine">
          <div class="row1">序号</div>
          <div class="row2"  style="text-align:left">单词</div>
          <div class="row3"  style="text-align:left">音标</div>
          <div class="row4"  style="text-align:left">单词</div>
          <div class="row5">
          <div class="stiduMa"> 学习</div>
            <div class="studyBox">
              <div>第1次</div>
                <div>第2次</div>
                <div>第3次</div>
                <div>第4次</div>
            </div>
          </div>

      </div>

      <div class="rowall ewg" v-for="(item, index) in content" :key="index">
          <div class="row1 ccc" style="text-align:center">
            
                      <img v-if="index < startCCC.value && listStart.value + '.txt'===q" class="checkboxx_start_red" src="http://img.ok-bug.com/app/star-red.png" alt="">

                       <img @click="completet(index + 1)" v-else class="checkboxx_start_grey" src="http://img.ok-bug.com/app/star-grey.png" alt="">

            {{index+1}}


            
          </div>
          <div class="row2 ccc" style="text-align:left">{{item.word}}</div>
          <div class="row3 ccc" style="text-align:left">{{item.pron}}</div>
          <div class="row4 ccc" style="text-align:left">
             <div v-for="(t, i) in item.trans" :key="i">
                      {{t}}


                  </div>
          </div>
          <div class="row5 ccc">
            <div class="ccbox">
              <div><img class="checkboxx" src="http://img.ok-bug.com/app/checkbox-hover.png" alt=""></div>
              <div><img class="checkboxx" src="http://img.ok-bug.com/app/checkbox-hover.png" alt=""></div>
              <div><img class="checkboxx" src="http://img.ok-bug.com/app/checkbox-hover.png" alt=""></div>
              <div><img class="checkboxx" src="http://img.ok-bug.com/app/checkbox-hover.png" alt=""></div>
            </div>
          </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    direction: "",
    content:[],
    sort: {
        name: '',
        order: 'asc'
      },
      columns: [
          { title: '单序号词', width:70, name: 'index' },
          { title: '单词', width: 163, name: 'word' },
          { title: '音标', name: 'calories', width: 160, align: 'center', sortable: true },
          { title: '翻译', name: 'fat', width: 329, align: 'center', sortable: true },
          { title: '学习', name: 'carbs', width: 75, align: 'center', sortable: true },
          { title: 'Protein (g)', name: 'protein', width: 75, align: 'center', sortable: true },
          { title: 'Iron (%)', name: 'iron', width:75, align: 'center', sortable: true },
           { title: 'Iron (%)', name: 'iron', width: 75, align: 'center', sortable: true }
      ],
      startCCC: {},
      q:'',
       listStart: ''

  }),
  methods:{

       async getStart(){
        const r = await this.$api.readStar({data: {key:'index'}})
     const c = await this.$api.readStar({data: {key:'list'}})

        this.startCCC = r

    this.listStart = c

        console.log(r,'rrr')

        },


        async completet(index){

            await this.$api.updateStar({data: {key:'index', value: index}})

            this.getStart()

        },

      getData(data){
        const xml = new XMLHttpRequest(); //声明对象
        xml.open("GET",`https://img.ok-bug.com/${data}`,false);
        xml.send(); //发送请求
        

        const aa  = xml.responseText

        const arr = aa.split(/\d+,/)
        arr.shift()
        


        const bb = []

        arr.forEach((item, index)=>{

            // console.log(item.indexOf(']'),'99')

            if(item.indexOf(']') !== -1) {
            const c = item.replace('[', 'aa[')
            const word = c.split('aa')[0].replace(/(\s*$)/g, "")

                    

            const another =  c.split('aa')[1].replace(/(\s*$)/g, "").toString()


            

            
            const dd = another.replace(']', ']@@')



            const t =  dd.split('@@')[1].replace(/(\s*$)/g, "")

                      const aaaa = t.replace(/([a-zA-Z]+.)+/g, (e) => {
                        if (e.indexOf('.') !== -1) {
                            return 'xx' + e;
                        }
                        return e
                        });

                        const eee = aaaa.split('xx');

                        
                        eee.shift();
                        
                        
                        bb.push({
                            index: index+1,
                            word,
                            pron: dd.split('@@')[0].replace(/(\s*$)/g, ""),
                            trans: eee.length === 0 ? [t] : eee ,
                        })
            }
           
          
          
        })

        this.content = bb


        setTimeout(()=>{

          this.dividePage()
        }, 1000)

        

      },
      handleSortChange ({name, order}) {
      this.list = this.list.sort((a, b) => order === 'asc' ? a[name] - b[name] : b[name] - a[name]);
    },

    dividePage(){
       var trs = document.getElementsByClassName('ewg')
      let sum = 0

      trs.forEach((item,index) =>{
      

        sum = sum+item.offsetHeight
          //  trs[index].style.display="inline-block"

        if(sum > 1300) {
          // console.log(sum)

          sum = 0
          trs[index].style.pageBreakBefore="always"
        }

      })

        // console.log(trs,'table')
    },


  },
  mounted(){
     const q = encodeURI(`${this.$route.query.a}`)
     this.q = q.split('/')[2] 
    console.log(this.q, 'qq')

    this.getStart()
    this.getData(q)
     
  }
};
</script>
<style>


.checkboxx_start_red {
  width: 15px;
  position: absolute;
  left: -20px
}


.checkboxx_start_grey {
   width: 25px;
  position: absolute;
  left: -25px
}

.ccbox {
  display: flex;
   justify-content: space-around;
}
.tableDiv {
  background: #fff;
}
.studyBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.stiduMa {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.rowallLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
  .rowall {
    display: flex;

    
  }
   .row1 {
      width: 70px;
      text-align: center;
      height: 80px;
      line-height: 80px;
      position: relative;

   }
    .row2 {
      width: 160px;
       text-align: center;
        height: 80px;
      line-height: 80px;
   }
    .row3 {
      width: 160px;
       text-align: center;
        height: 80px;
      line-height: 80px;
   }
    .row4 {
      width: 329px;
       text-align: center;
        height: 80px;
      line-height: 80px;
   }
    .row5 {
      width: 300px;
       text-align: center;
        height: 80px;
   }

   .ccc {
      height:auto;
      line-height: 20px;
      padding: 15px 0;
      position: relative;
       border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   }

   .ccbox {
     position: absolute;
     width: 100%;
     top: 50%;
     transform: translateY(-50%);
   }

    .text {
        width: 1024px;
        margin: 0 auto;
    }
    .row1 {
        display: flex;
        
    }
    .item {
        width: 200px;
    }

    .rowname {
        font-weight: bolder;
        margin-bottom: 20px;
    }
    .checkboxx {
        width: 15px;
        height: 15px;
    }


</style>